import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaSignInAlt } from "react-icons/fa"
import api from  '../../services/api'
import logo_atmo from '../../assets/logo_atmo_login_new.png'
import './style.css'


export default function InsertHash() {
  const [hash, setHash] = useState('')
  const history = useNavigate()

  useEffect(() => {
    if (localStorage.getItem("config") != null) {
      return history('/home')
    } 
    
    let cancel = false

    if (!cancel) {

      history('/')
    }

    return () => {
      cancel = true
    }
    // eslint-disable-next-line
  }, [history])

  async function saveHash(newHash, newLoja, infosLoja) {
    try {
      const hashObj = { hash: newHash, loja: newLoja, streamingMode: true }

      localStorage.setItem('infos', JSON.stringify(infosLoja))
      localStorage.setItem('config', JSON.stringify(hashObj))

      console.log('Hash salvo')
      return true
    } catch (err) {
      console.log(err)
    }
  }

  async function handleLogin(e) {
    e.preventDefault()

    try {
      const response = await api.get(`operacao/maquina/info/${hash}`)

      saveHash(hash, response.data.nome_fantasia_local, response.data)

      history('/home')
    } catch (err) {
      if (err.response.status === 404 || err.response.status === 500) {
        alert('Digite uma hash válida!')
      } else {
        alert('Tente novamente mais tarde!')
      }
      console.log(err)
    }
  }

  return (
    <div className="container">
      <div id="divLogo" className="row justify-content-center wrapper fadeInDown">
        <div className="mx-auto img-responsive">
          <img src={logo_atmo} id="logo" alt="Logo Atmo" />
        </div>
      </div>
      <div className="card card-container">
        <form className="form-signin" onSubmit={handleLogin}>
          <input onChange={(e) => setHash(e.target.value.toUpperCase().replace(' ', ''))} value={hash} type="text" id="hash" name="hash" className="form-control" placeholder="Insira a Hash" required />
          <button className="btn btn-lg btn-block btn-signin" type="submit">
            Entrar <FaSignInAlt />
          </button>
        </form>
      </div>
    </div>
  )
}
