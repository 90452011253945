import { useState } from 'react'
import PlayerBar from '../../components/playerbar'
import NavBar from '../../components/navbar'
import FormGroup from '@mui/material/FormGroup'
import TextField from '@mui/material/TextField'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import api from '../../services/api'
import './style.css'

export default function NewJingle() {
  const menuState = useSelector((state) => state.menuIsCollapsed)
  const [jingleName, setJingleName] = useState('')
  const [jingleText, setJingleText] = useState('')
  const [submitDisabled, setSubmitDisabled] = useState(false)

  function createJingle() {
    setSubmitDisabled(true)

    const infos = JSON.parse(localStorage.getItem('infos') || '{}')

    api.post('/music/storeJingle', {
        name: jingleName,
        texto: jingleText,
        id_ambiente: infos.id_ambiente,
        id_local: infos.id_local,
        id_maquina: infos.id_maquina,
      })
      .then((res) => {
        setJingleName('')
        setJingleText('')
        setSubmitDisabled(false)

        if (res) {
          alert('Jingle criado com sucesso!')
        } else {
          alert('Erro ao criar o jingle, por favor verifique os campos e tente novamente.')
        }
      })
  }

  return (
    <>
      <PlayerBar />
      <NavBar />
      <div className={menuState ? 'pageContainerMenuOpen jinglesPage' : 'pageContainerMenuClosed jinglesPage'}>
        <h1 style={{ marginLeft: '15px' }}>Criação de Jingles</h1>

        <FormGroup>
          <TextField
            style={{ marginLeft: '15px', marginTop: '15px', width: '60vw' }}
            className='nameField'
            label={<p style={{ color: 'white' }}>Nome</p>}
            value={jingleName}
            onChange={(e) => setJingleName(e.target.value)}
          />

          <TextField
            style={{ marginLeft: '15px', marginTop: '15px', width: '60vw' }}
            className='textField'
            multiline
            maxRows={20}
            label={<p style={{ color: 'white' }}>Texto da campanha</p>}
            value={jingleText}
            onChange={(e) => setJingleText(e.target.value)}
          />

          <Button
            disabled={submitDisabled}
            variant="contained"
            style={{ marginLeft: '15px', marginTop: '15px', width: '150px', backgroundColor: '#74ac2d', color: 'black', fontWeight: 'bold' }}
            onClick={createJingle}
          >
            Salvar
          </Button>
        </FormGroup>
      </div>
    </>
  )
}
