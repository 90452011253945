import React from 'react'
import { Route, Routes, HashRouter } from 'react-router-dom'

import InsertHash from './pages/InsertHash'
import Home from './pages/Home'
import Music from './pages/Music'
import Favs from './pages/Favs'
import Playlist from './pages/Playlist'
import Config from './pages/Config'
import NewJingle from './pages/NewJingle'

export default function AppRoutes() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" exact element={<InsertHash />} />
        <Route path="/home" element={<Home />} />
        <Route path="/musics" element={<Music />} />
        <Route path="/favs" element={<Favs />} />
        <Route path="/playlists" element={<Playlist />} />
        <Route path="/configs" element={<Config />} />
        <Route path="/newJingle" element={<NewJingle />} />
      </Routes>
    </HashRouter>
  )
}
