import { Slider } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { BsFillPlayCircleFill, BsFillPauseCircleFill } from "react-icons/bs"
import { IoPlaySkipForward, IoPlaySkipBack } from "react-icons/io5"
import { TiVolumeUp, TiVolumeMute } from "react-icons/ti"
import { FcLike, FcLikePlaceholder } from "react-icons/fc"
import { FaRandom } from "react-icons/fa"
import { BiRepeat } from "react-icons/bi"
import { useSelector, useDispatch } from 'react-redux'
import './style.css'
import api from '../../services/api'

export default function PlayerBar() {
  const musicIsPlaying = useSelector((state) => state.musicIsPlaying)
  const currentMusicName = useSelector((state) => state.currentMusicName)
  const currentJingleName = useSelector((state) => state.currentJingleName)
  const currentMusicData = useSelector((state) => state.currentMusicData)
  const currentJingleData = useSelector((state) => state.currentJingleData)
  const musicIsPaused = useSelector((state) => state.musicIsPaused)
  const musicsArray = useSelector((state) => state.musicsArray)
  const jinglesArray = useSelector((state) => state.jinglesArray)
  const allowPlayJingle = useSelector((state) => state.allowPlayJingle)
  const [soundButton, setSoundButton] = useState(true)
  const [musicCurrentTime, setMusicCurrentTime] = useState(currentMusicName ? convertHMS(musicIsPlaying.currentTime) : '00:00')
  const [volume, setVolume] = useState(currentMusicName ? musicIsPlaying.volume * 100 : 50)
  const musics = useSelector((state) => state.musicsArray)
  const [idLocal, setIdLocal] = useState('')
  const [like, setLike] = useState(false)
  const [musicTime, setMusicTime] = useState(currentMusicName ? (musicIsPlaying.currentTime / musicIsPlaying.duration) * 100 : 0)
  const dispatch = useDispatch()
  const setRatedLike = async () => {
    if (!like) {
      if (allowPlayJingle) {
        const currentSong = jinglesArray.filter(jingle => jingle.arquivo === currentJingleName)
  
        setLike(true)
  
        await api.get(`/music/ratedMusic/${currentSong[0].id_musica}/${idLocal}/like`)
      } else if (currentMusicName) {
        const currentSong = musics.filter(music => music.arquivo === currentMusicName)
  
        setLike(true)
  
        await api.get(`/music/ratedMusic/${currentSong[0].id_musica}/${idLocal}/like`)
        
      }
    } else  {

      if (allowPlayJingle) {
        const currentSong = jinglesArray.filter(jingle => jingle.arquivo === currentJingleName)
  
        setLike(false)
  
        await api.get(`/music/ratedMusic/${currentSong[0].id_musica}/${idLocal}/null`)
      } else if (currentMusicName) {
        const currentSong = musics.filter(music => music.arquivo === currentMusicName)
  
        setLike(false)
  
        await api.get(`/music/ratedMusic/${currentSong[0].id_musica}/${idLocal}/null`)
      }
    }
    
  }
  function convertHMS(value) {
    const sec = parseInt(value, 10)
    let hours = Math.floor(sec / 3600)
    let minutes = Math.floor((sec - (hours * 3600)) / 60)
    let seconds = sec - (hours * 3600) - (minutes * 60)

    if (hours < 10) { hours = "0" + hours }
    if (minutes < 10) { minutes = "0" + minutes }
    if (seconds < 10) { seconds = "0" + seconds }
    return minutes + ':' + seconds
  }

  const updateMusicTime = () => {
    if (currentMusicName && musicIsPlaying.currentTime !== 0) {
      setMusicTime((musicIsPlaying.currentTime / musicIsPlaying.duration) * 100)
      setMusicCurrentTime(convertHMS(musicIsPlaying.currentTime))
    }
  }

  const setPlayOrPause = () => {
    if (currentMusicName) {
      !musicIsPaused ? musicIsPlaying.pause() : musicIsPlaying.play()
    }

    !musicIsPaused ? dispatch({ type: 'SET_IF_MUSIC_IS_PAUSED', musicIsPaused: true }) : dispatch({ type: 'SET_IF_MUSIC_IS_PAUSED', musicIsPaused: false })
  }

  const setSound = () => {
    soundButton ? setSoundButton(false) : setSoundButton(true)
    soundButton ? setVolume(0) : setVolume(musicIsPlaying.volume * 100)

    soundButton ? musicIsPlaying.muted = true : musicIsPlaying.muted = false
  }

  const changeVolume = (e, value) => {
    musicIsPlaying.volume = value / 100

    setVolume(value)
    volume === 0 ? setSoundButton(false) : setSoundButton(true)
  }

  const changeMusicTime = (e, value) => {
    const timeInSeconds = !isNaN(musicIsPlaying.duration) ? (value / 100) * musicIsPlaying.duration : 0

    musicIsPlaying.currentTime = timeInSeconds

    setMusicTime(value)
  }

  const playNextMusic = () => {
    if (allowPlayJingle) {
      dispatch({ type: 'ALLOW_PLAY_JINGLE', allowPlayJingle: false })
    }

    if (randomIsActive) {
      if (currentMusicName) {
        const randomMusicIndex = getRandom(musicsArray.length - 1)

        musicIsPlaying.src = musicsArray[randomMusicIndex].streaming_url

        dispatch({ type: 'SET_IF_MUSIC_IS_PAUSED', musicIsPaused: false })

        const playPromise = musicIsPlaying.play()

        if (playPromise !== null) {
          playPromise.catch((err) => {
            if (localStorage.getItem("falhas") == null) {
              const hashObj = { data: new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }), codErro: err.response, mensagemErro: err.message, URL: err.request }
              localStorage.setItem("falhas", JSON.stringify(hashObj), (err) => {
                if (err) {
                  throw err
                }
              })
            } else {
              const hashObj = { data: new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }), codErro: err.response, mensagemErro: err.message, URL: err.request }
              appendToLocalStorage("falhas", "\r\n" + JSON.stringify(hashObj), (err) => {
                if (err) {
                  throw err
                }
              })
            }
          })
        }

        dispatch({ type: 'CURRENT_MUSIC_NAME', musicName: musicsArray[randomMusicIndex].arquivo })

        dispatch({
          type: 'CURRENT_MUSIC_DATA', musicData: {
            name: musicsArray[randomMusicIndex].nome,
            artist: musicsArray[randomMusicIndex].artista,
            thumbnail: musicsArray[randomMusicIndex].playlist_thumbnail
          }
        })
      }
    } else {
      if (currentMusicName) {
        const currentSong = musicsArray.filter(music => music.arquivo === currentMusicName)

        if (musicsArray.indexOf(currentSong[0]) + 1 !== musicsArray.length) {
          const nextSong = musicsArray[musicsArray.indexOf(currentSong[0]) + 1]

          musicIsPlaying.src = nextSong.streaming_url

          dispatch({ type: 'SET_IF_MUSIC_IS_PAUSED', musicIsPaused: false })

          const playPromise = musicIsPlaying.play()

          if (playPromise !== null) {
            playPromise.catch((err) => {
              if (localStorage.getItem("falhas") == null) {
                const hashObj = { data: new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }), codErro: err.response, mensagemErro: err.message, URL: err.request }
                localStorage.setItem("falhas", JSON.stringify(hashObj), (err) => {
                  if (err) {
                    throw err
                  }
                })
              } else {
                const hashObj = { data: new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }), codErro: err.response, mensagemErro: err.message, URL: err.request }
                appendToLocalStorage("falhas", "\r\n" + JSON.stringify(hashObj), (err) => {
                  if (err) {
                    throw err
                  }
                })
              }
            })
          }

          dispatch({ type: 'CURRENT_MUSIC_NAME', musicName: nextSong.arquivo })

          dispatch({
            type: 'CURRENT_MUSIC_DATA', musicData: {
              name: nextSong.nome,
              artist: nextSong.artista,
              thumbnail: nextSong.playlist_thumbnail
            }
          })
        } else {
          musicIsPlaying.src = musicsArray[0].streaming_url

          dispatch({ type: 'SET_IF_MUSIC_IS_PAUSED', musicIsPaused: false })

          const playPromise = musicIsPlaying.play()

          if (playPromise !== null) {
            playPromise.catch((err) => {
              if (localStorage.getItem("falhas") == null) {
                const hashObj = { data: new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }), codErro: err.response, mensagemErro: err.message, URL: err.request }
                localStorage.setItem("falhas", JSON.stringify(hashObj), (err) => {
                  if (err) {
                    throw err
                  }
                })
              } else {
                const hashObj = { data: new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }), codErro: err.response, mensagemErro: err.message, URL: err.request }
                appendToLocalStorage("falhas", "\r\n" + JSON.stringify(hashObj), (err) => {
                  if (err) {
                    throw err
                  }
                })
              }
            })
          }

          dispatch({ type: 'CURRENT_MUSIC_NAME', musicName: musicsArray[0].arquivo })

          dispatch({
            type: 'CURRENT_MUSIC_DATA', musicData: {
              name: musicsArray[0].nome,
              artist: musicsArray[0].artista,
              thumbnail: musicsArray[0].playlist_thumbnail
            }
          })
        }
      }
    }
  }

  function appendToLocalStorage(key, valueToAppend) {
    let currentValue = localStorage.getItem(key);
    
    if (currentValue === null) {
        currentValue = "";
    }
    
    const newValue = currentValue + valueToAppend;
    
    localStorage.setItem(key, newValue);
}

  const playPreviousMusic = () => {
    if (allowPlayJingle) {
      dispatch({ type: 'ALLOW_PLAY_JINGLE', allowPlayJingle: false })
    }

    if (currentMusicName) {
      const currentSong = musicsArray.filter(music => music.arquivo === currentMusicName)

      if ((musicsArray.indexOf(currentSong[0]) - 1) >= 0) {
        const nextSong = musicsArray[musicsArray.indexOf(currentSong[0]) - 1]

        musicIsPlaying.src = nextSong.streaming_url

        dispatch({ type: 'SET_IF_MUSIC_IS_PAUSED', musicIsPaused: false })

        const playPromise = musicIsPlaying.play()

        if (playPromise !== null) {
          playPromise.catch((err) => {
            if (localStorage.getItem("falhas") == null) {
              const hashObj = { data: new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }), codErro: err.response, mensagemErro: err.message, URL: err.request }
              localStorage.setItem("falhas", JSON.stringify(hashObj), (err) => {
                if (err) {
                  throw err
                }
              })
            } else {
              const hashObj = { data: new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }), codErro: err.response, mensagemErro: err.message, URL: err.request }
              appendToLocalStorage("falhas", "\r\n" + JSON.stringify(hashObj), (err) => {
                if (err) {
                  throw err
                }
              })
            }
          })
        }

        dispatch({ type: 'CURRENT_MUSIC_NAME', musicName: nextSong.arquivo })

        dispatch({
          type: 'CURRENT_MUSIC_DATA', musicData: {
            name: nextSong.nome,
            artist: nextSong.artista,
            thumbnail: nextSong.playlist_thumbnail
          }
        })
      } else {
        musicIsPlaying.src = musicsArray[musicsArray.length - 1].streaming_url

        dispatch({ type: 'SET_IF_MUSIC_IS_PAUSED', musicIsPaused: false })

        const playPromise = musicIsPlaying.play()

        if (playPromise !== null) {
          playPromise.catch((err) => {
            if (localStorage.getItem("falhas") == null) {
              const hashObj = { data: new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }), codErro: err.response, mensagemErro: err.message, URL: err.request }
              localStorage.setItem("falhas", JSON.stringify(hashObj), (err) => {
                if (err) {
                  throw err
                }
              })
            } else {
              const hashObj = { data: new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }), codErro: err.response, mensagemErro: err.message, URL: err.request }
              appendToLocalStorage("falhas", "\r\n" + JSON.stringify(hashObj), (err) => {
                if (err) {
                  throw err
                }
              })
            }
          })
        }

        dispatch({ type: 'CURRENT_MUSIC_NAME', musicName: musicsArray[musicsArray.length - 1].arquivo })

        dispatch({
          type: 'CURRENT_MUSIC_DATA', musicData: {
            name: musicsArray[musicsArray.length - 1].nome,
            artist: musicsArray[musicsArray.length - 1].artista,
            thumbnail: musicsArray[musicsArray.length - 1].playlist_thumbnail
          }
        })
      }
    }
  }

  const getRandom = (max) => {
    return Math.floor(Math.random() * max)
  }

  const repeatMusic = () => {
    if (currentMusicName) {
      musicIsPlaying.currentTime = 0

      if (musicIsPaused) {
        dispatch({ type: 'SET_IF_MUSIC_IS_PAUSED', musicIsPaused: false })

        musicIsPlaying.play()
      }
    }
  }
  const [randomIsActive, setRandomIsActive] = useState(false)

  const playRandomMusic = () => {
    setRandomIsActive(!randomIsActive)
    if (!randomIsActive) {
      dispatch({ type: 'RANDOM_IS_ACTIVE', randomIsActive: true })
    } else {
      dispatch({ type: 'RANDOM_IS_ACTIVE', randomIsActive: false })
    }
  }

  useEffect(() => {
    let cancel = false

    const timer = setInterval(updateMusicTime, 1000)

    async function fetchData(currentSong) {
      await api.get(`/music/showRated/${currentSong[0].id_musica}/${idLocal}`).then((res) => {
        if (cancel) return

        if (res.data.opcao) {
          if (res.data.opcao === "like") {
            if (cancel) return

            setLike(true)
          } else {
            if (cancel) return

            setLike(false)
          }
        } else {
          if (cancel) return

          setLike(false)
        }
      }).catch((err) => {
        if (localStorage.getItem("falhas") == null) {
          const hashObj = { data: new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }), codErro: err.response, mensagemErro: err.message, URL: err.request }
          localStorage.setItem("falhas", JSON.stringify(hashObj), (err) => {
            if (err) {
              throw err
            }
          })
        } else {
          const hashObj = { data: new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }), codErro: err.response, mensagemErro: err.message, URL: err.request }
          appendToLocalStorage("falhas", "\r\n" + JSON.stringify(hashObj), (err) => {
            if (err) {
              throw err
            }
          })
        }
      })
    }

    if (localStorage.getItem("config") != null) {
      let infos = JSON.parse(localStorage.getItem("infos"))
      
      setIdLocal(infos.id_local)
    }

    if (allowPlayJingle) {
      const currentSong = jinglesArray.filter(jingle => jingle.arquivo === currentJingleName)

      fetchData(currentSong)
    } else if (currentMusicName) {
      const currentSong = musics.filter(music => music.arquivo === currentMusicName)

      fetchData(currentSong)
    }

    return () => {
      cancel = true
      clearInterval(timer)
    }

    // eslint-disable-next-line
  }, [currentMusicName, musicIsPlaying, idLocal, musics, dispatch, allowPlayJingle])

  return (
    <div id="player-bar">
      <div className="infos-artista">
        <div id="miniatura">
          <div id="thumbnail" style={{ backgroundImage: `url(${allowPlayJingle ? currentJingleData.thumbnail : currentMusicData.thumbnail})`, backgroundSize: 'contain', borderRadius: '5px' }}></div>
        </div>
        <div id="info-music">
          <div className="scroll-container-musica">
            <p className="scrolling-text-musica" id="musica">{allowPlayJingle ? currentJingleData.name : currentMusicData.name}</p>
          </div>
          <div className="scroll-container-cantor">
            <p className="scrolling-text-cantor" id="cantor">{allowPlayJingle ? currentJingleData.artist : currentMusicData.artist}</p>
          </div>
        </div>

        <div id="avaliacao">
          <>
            <div id="change-status" onClick={setRatedLike}>
              {like ? (
                <FcLike className="avaliacao-icon" title="Curti" />
              ) : (
                <FcLikePlaceholder className="avaliacao-icon" title="Curti" />
              )}
            </div>
          </>
        </div>
      </div>
      <div className="center">
        <div id="button-control" className="d-flex justify-content-center">
          <FaRandom id="random-music" className={randomIsActive ? 'control active' : 'control'} onClick={playRandomMusic} />
          <IoPlaySkipBack id="back-music" className='control' onClick={playPreviousMusic} />
          {musics.length === 0 ? (
            <div id="change-status" onClick={setPlayOrPause}>
              <BsFillPlayCircleFill id="play-pause" className='control-play' />
            </div>
          ) : (
            <div id="change-status" onClick={setPlayOrPause}>
              {!musicIsPaused ? (
                <BsFillPauseCircleFill id="play-pause" className='control-play' />
              ) : (
                <BsFillPlayCircleFill id="play-pause" className='control-play' />
              )}
            </div>
          )
          }
          <IoPlaySkipForward id="next-music" className='control' onClick={playNextMusic} />
          <BiRepeat id="repeat-music" className='control' onClick={repeatMusic} />
        </div>
        <div id="progresso" className="d-flex flex-wrap justify-content-center">
          <span className="tempo" id="start-music">{musicCurrentTime}</span>
          <Slider className={currentMusicName ? 'col-md-1 col-xs-12' : 'blockClick'} size="small" min={0} max={100} default={100} value={musicTime} title={musicTime} onChange={changeMusicTime} id="musicTime" />
          <span className="tempo" id="end-music">{currentMusicName && !isNaN(musicIsPlaying.duration) ? convertHMS(musicIsPlaying.duration) : '00:00'}</span>
        </div>
      </div>
      <div className="controle-volume">
        <div id="change-volume" onClick={setSound}>
          {soundButton ? (
            <TiVolumeUp className='volume-icon' />
          ) : (
            <TiVolumeMute className='volume-icon' />
          )}
        </div>
        <Slider min={0} max={100} default={100} value={volume} title={volume + "%"} onChange={changeVolume} id="volume" className="col-md-1 col-xs-12" />
      </div>
    </div>
  )
}