import { combineReducers } from 'redux'

function menuIsCollapsed(state = true, action) {
  switch (action.type) {
    case 'MENU_STATE':
      return action.menuState
    default:
      return state
  }
}

function musicIsPlaying(state = false, action) {
  switch (action.type) {
    case 'MUSIC_IS_PLAYING':
      return action.musicPlayer
    default:
      return state
  }
}

function musicsArray(state = [], action) {
  switch (action.type) {
    case 'MUSICS_ARRAY':
      return action.musicsArray
    default:
      return state
  }
}

function jinglesArray(state = [], action) {
  switch (action.type) {
    case 'JINGLES_ARRAY':
      return action.jinglesArray
    default:
      return state
  }
}

function currentMusicName(state = '', action) {
  switch (action.type) {
    case 'CURRENT_MUSIC_NAME':
      return action.musicName
    default:
      return state
  }
}

function currentJingleName(state = '', action) {
  switch (action.type) {
    case 'CURRENT_JINGLE_NAME':
      return action.jingleName
    default:
      return state
  }
}

function currentMusicData(state = {}, action) {
  switch (action.type) {
    case 'CURRENT_MUSIC_DATA':
      return action.musicData
    default:
      return state
  }
}

function currentJingleData(state = {}, action) {
  switch (action.type) {
    case 'CURRENT_JINGLE_DATA':
      return action.jingleData
    default:
      return state
  }
}

function periodicidadeJingle(state = 0, action) {
  switch (action.type) {
    case 'PERIODICIDADE_JINGLE':
      return action.periodicidadeJingle
    default:
      return state
  }
}

function allowPlayJingle(state = false, action) {
  switch (action.type) {
    case 'ALLOW_PLAY_JINGLE':
      return action.allowPlayJingle
    default:
      return state
  }
}

function musicIsPaused(state = false, action) {
  switch (action.type) {
    case 'SET_IF_MUSIC_IS_PAUSED':
      return action.musicIsPaused
    default:
      return state
  }
}

function isDownloading(state = false, action) {
  switch (action.type) {
    case 'SET_IF_MUSIC_IS_DOWNLOADING':
      return action.isDownloading
    default:
      return state
  }
}

function randomIsActive(state = false, action) {
  switch (action.type) {
    case 'RANDOM_IS_ACTIVE':
      return action.randomIsActive
    default:
      return state
  }
}

const allReducers = combineReducers({
    menuIsCollapsed,
    musicIsPlaying,
    musicsArray,
    currentMusicName,
    currentMusicData,
    musicIsPaused,
    jinglesArray,
    currentJingleName,
    currentJingleData,
    periodicidadeJingle,
    allowPlayJingle,
    isDownloading,
    randomIsActive
})

export default allReducers