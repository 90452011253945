import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import NavBar from '../../components/navbar'
import PlayerBar from '../../components/playerbar'
import { useSelector, useDispatch } from 'react-redux'
import './style.css'
import api from '../../services/api'
import { FaUserCircle } from 'react-icons/fa'
import { ImEnter } from 'react-icons/im'
import { MdPlace, MdDateRange } from 'react-icons/md'
import { HiDownload } from 'react-icons/hi'
import { RiArrowGoBackFill } from 'react-icons/ri'
import Box from '@mui/material/Box'
import MusicsList from '../../components/musicsList'
import BigLogo from '../../assets/256x256.png'
import ClipLoader from 'react-spinners/ClipLoader'

export default function Home() {
  const history = useNavigate()
  const dispatch = useDispatch()
  const menuState = useSelector((state) => state.menuIsCollapsed)
  const [loja, setLoja] = useState()
  const [hash, setHash] = useState()
  const [nomeLocal, setNomeLocal] = useState()
  const [email, setEmail] = useState()
  const [playlists, setPlaylists] = useState([])
  const [dataInstalacao, setDataInstalacao] = useState([])
  const [dataDownload, setDataDownload] = useState([])
  const [musics, setMusics] = useState([])
  const [playlistIsOpen, setPlaylistIsOpen] = useState(false)
  const [openedPlaylistId, setOpenedPlaylistId] = useState('')
  const [openedPlaylistName, setOpenedPlaylistName] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  let [loaderColor] = useState('#ffffff')

  useEffect(() => {
    let cancel = false
  
    async function fetchData(dataJson) {
      await api.get(`/operacao/playlist/indexByHash/${dataJson.hash}`).then((res) => {
        if (cancel) return
  
        setIsLoading(false)
        setPlaylists(res.data)
      })
  
      await api.get(`/music/infoMusicsByHash/${dataJson.hash}`).then((res) => {
        if (cancel) return
  
        setIsLoading(false)
        setMusics(res.data)
  
        if (cancel) return
      })
  
      await api.get(`/operacao/playlist/lista_cache/${dataJson.hash}`).then((res) => {
        if (cancel) return
  
        const jinglesData = JSON.stringify(res.data)
        localStorage.setItem('jinglesData', jinglesData)
      })

      await api.get(`/music/infoJinglesByHash/${dataJson.hash}`).then((res) => {
        if (cancel) return
  
        setIsLoading(false)
        dispatch({ type: 'JINGLES_ARRAY', jinglesArray: res.data })
  
        
        if (!infos) {
          const offlineJingles = JSON.stringify(res.data)
          localStorage.setItem('offlineJingles', offlineJingles)
        }
        console.log('Jingles salvos')
      })

      
  
      const jinglesData = JSON.parse(localStorage.getItem('jinglesData') || '{}')
      if (jinglesData) {
        if (cancel) return

        dispatch({ type: 'PERIODICIDADE_JINGLE', periodicidadeJingle: 4 })
      } else {
        if (cancel) return
  
        dispatch({ type: 'PERIODICIDADE_JINGLE', periodicidadeJingle: 0 })
      }
    }
  
    const infos = JSON.parse(localStorage.getItem('infos') || '{}')
    if (localStorage.getItem("infos") == null) {
      history('/')
    } else {
      if (cancel) return
      setLoja(infos.nome_fantasia_local)
      setHash(infos.hash)
      setNomeLocal(infos.nome_local)
      setEmail(infos.email_local)
      setDataInstalacao(infos.data_instalacao_local)
      setDataDownload(infos.data_download_local)
  
      fetchData(infos)
    }
  
    return () => {
      cancel = true
    }
  }, [history, dispatch])
  

  function enterPlaylist(playlistId, playlistName) {
    var playlistDetails = JSON.parse(localStorage.getItem('jinglesData') || '{}')[playlistName]
    if (playlistDetails) {
      dispatch({ type: 'PERIODICIDADE_JINGLE', periodicidadeJingle: playlistDetails[0].periodicidade_jingle })
    }
    setOpenedPlaylistId(playlistId)
    setOpenedPlaylistName(playlistName)
    setPlaylistIsOpen(true)
  }

  function backToPlaylists() {
    setOpenedPlaylistId('')
    setOpenedPlaylistName('')
    setPlaylistIsOpen(false)
  }

  return (
    <>
      <PlayerBar />
      <NavBar />
      <div className={menuState ? 'pageContainerMenuOpen homePage' : 'pageContainerMenuClosed homePage'}>
        <div className='pageHeader'>
          <h1 style={{ marginLeft: '15px', color: 'white' }}>{openedPlaylistName !== '' ? openedPlaylistName : ''}</h1>
          {playlistIsOpen ? <RiArrowGoBackFill onClick={backToPlaylists} className='backButton' /> : ''}
        </div>

        {playlistIsOpen ? (
          ''
        ) : (
          <Box
            component='form'
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete='off'
          ></Box>
        )}

        { playlistIsOpen ? (<MusicsList playlistId={openedPlaylistId} />) : !isLoading ? (
              <>
                <div id='infos'>
                  <div className='col-md-6'>
                    <h6 className='blue'><FaUserCircle className='icon' /> { loja }</h6>
                    <h6 className='blue'># { hash }</h6>
                    <h6 className='blue'><MdPlace className='icon' /> { nomeLocal }</h6>
                  </div>
                  <div className='col-md-6'>
                    <h6 className='blue'>@ { email }</h6>
                    <h6 className='blue'><MdDateRange className='icon' /> { dataInstalacao }</h6>
                    <h6 className='blue'><HiDownload className='icon' /> { dataDownload }</h6>
                  </div>
                </div>
                <div id='allPlaylists'>
                  {
                    playlists.map(playlist => (
                      <figure onClick={() => enterPlaylist(playlist.id, playlist.nome)} className='playListInfo'  key={playlist.id}>
                        <img className='imgPlaylist' src={ playlist.thumbnail ? playlist.thumbnail : BigLogo } alt='playlist' />
                        <figcaption className='descImage' style={{ fontWeight: 'bolder' }}
                          title={musics.filter(music => music.playlist_name === playlist.nome).map((musicNew) => (
                            ' ' + musicNew.artista
                          ))}
                        >
                          {musics.filter(music => music.playlist_name === playlist.nome).map((musicNew) => (
                            '*' + musicNew.artista + '* '
                          ))}
                        </figcaption>
                        <div className='enterIconWraperHome'><ImEnter className='enterIconHome'/></div>
                      </figure>
                    ))
                  }
                </div>
              </>
            ) : ( <div className='loaderIcon'> <ClipLoader color={loaderColor} size={150} /> </div> )
          }
      </div>
    </>
  )
}
