import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import NavBar from '../../components/navbar'
import { useSelector } from 'react-redux'
import PlayerBar from '../../components/playerbar'
import FormGroup from '@mui/material/FormGroup'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import './style.css'

export default function Config() {
  const history = useNavigate()
  const menuState = useSelector((state) => state.menuIsCollapsed)
  const [ open, setOpen ] = useState(false)

  const handleModalOpen = () => setOpen(true)
  const handleModalClose = () => setOpen(false)

  useEffect(() => {
    let cancel = false

    if (localStorage.getItem("config") == null) {
      localStorage.getItem("config", (err, data) => {
        if (!data) {
          history('/')
        } else {
          if (cancel) return
        } 
      })
    }
    
    return () => { 
      cancel = true
    }
  }, [history])

  const switchColorTheme = createTheme({
    palette: {
      atmoGreen: {
        main: '#74ac2d'
      },
    },
  })

  const buttonStyle = {
    marginRight: '15px',
    backgroundColor: '#74ac2d',
    color: 'black',
    fontWeight: 'bold',
    marginTop: '15px'
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  }

  const closeApp = (e) => {
    e.preventDefault()
      
    try {
        localStorage.removeItem("config")
        history('/')
    } catch (err) {
      console.log(err)
        const hashObj = { data: new Date().toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'}), codErro: err.response.status, mensagemErro: err.message, URL: err.request.responseURL }
        localStorage.setItem("falhas", JSON.stringify(hashObj), (err) => {
          if (err) {
            throw err
          }
        })
    }
  }

  return (
    <>
      <NavBar/>
      <PlayerBar/>
      <div className={menuState ? 'pageContainerMenuOpen configsPage' : 'pageContainerMenuClosed configsPage'}>
        <h1 style={{ marginLeft: '15px' }}>Configurações</h1>

        <FormGroup style={{ marginLeft: '15px' }}>
          <ThemeProvider theme={switchColorTheme}>
            <Button style={buttonStyle} className='logoutButton' variant='contained' onClick={handleModalOpen}>Logout</Button>
          </ThemeProvider>
        </FormGroup>

        <Modal
          open={open}
          onClose={handleModalClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={modalStyle}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Tem certeza que deseja fazer logout?
            </Typography>
            <div className='buttonsContainer'>
              <Button style={buttonStyle} className='yesButton' variant='contained' onClick={closeApp}>Sim</Button>
              <Button style={buttonStyle} variant='contained' onClick={handleModalClose}>Não</Button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  )
}