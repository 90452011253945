import React, { useEffect } from 'react'
import './global.css'
import AppRoutes from './routes'
import Audio from './components/audio'
// import { startJobs } from './utils/jobs'
// import { deleteInvalidPlaylists } from './utils/deleteInvalidPlaylists'
// import { deleteInvalidMusics } from './utils/deleteInvalidMusics'
// import { deleteInvalidJingles } from './utils/deleteInvalidJingles'
import api from './services/api'

function App() {
  const config = JSON.parse(localStorage.getItem('config') || '{}')
  const infosPath = config.infosPath

  useEffect(() => {
    // startJobs()

    async function fetchData() {
      if (infosPath) {
        const dataJson = JSON.parse(infosPath)

        // deleteInvalidPlaylists(config.playlistsPath, dataJson.hash)
        // deleteInvalidMusics(config.playlistsPath, dataJson.hash)
        // deleteInvalidJingles(config.playlistsPath, dataJson.hash)

        await api
          .post('/operacao/maquina/status', {
            hash: dataJson.hash,
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }

    fetchData()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Audio />
      <AppRoutes />
    </>
  )
}

export default App
