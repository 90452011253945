import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import NavBar from '../../components/navbar'
import PlayerBar from '../../components/playerbar'
import { useSelector, useDispatch } from 'react-redux'
import api from '../../services/api'
import { ImEnter } from 'react-icons/im'
import './style.css'
import MusicsList from '../../components/musicsList'
import { RiArrowGoBackFill } from 'react-icons/ri'
import { FaSearch } from 'react-icons/fa'
import BigLogo from '../../assets/256x256.png'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import ClipLoader from 'react-spinners/ClipLoader'
import { BeatLoader } from 'react-spinners'

export default function Playlist() {
  const dispatch = useDispatch()
  const history = useNavigate()
  const menuState = useSelector((state) => state.menuIsCollapsed)
  const isDownloading = useSelector((state) => state.isDownloading)
  const [playlists, setPlaylists] = useState([])
  const [playlistIsOpen, setPlaylistIsOpen] = useState(false)
  const [openedPlaylistId, setOpenedPlaylistId] = useState('')
  const [openedPlaylistName, setOpenedPlaylistName] = useState('')
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  // eslint-disable-next-line
  let [loaderColor, setLoaderColor] = useState('#ffffff')

  useEffect(() => {
    let cancel = false

    async function fetchData(hash) {
      await api.get(`/operacao/playlist/indexByHash/${hash}`).then((res) => {
        if (cancel) return

        setIsLoading(false)
        setPlaylists(res.data)
      })
    }

    const config = JSON.parse(localStorage.getItem('config') || '{}')
    if (config && config.hash) {
      fetchData(config.hash)
    } else {
      history('/')
    }

    return () => {
      cancel = true
    }
  }, [history])

  function enterPlaylist(playlistId, playlistName) {
    var playlistDetails = JSON.parse(localStorage.getItem('jinglesData') || '{}')[playlistName]
    if (playlistDetails) {
      dispatch({ type: 'PERIODICIDADE_JINGLE', periodicidadeJingle: playlistDetails[0].periodicidade_jingle })
    }
    setOpenedPlaylistId(playlistId)
    setOpenedPlaylistName(playlistName)
    setPlaylistIsOpen(true)
  }

  function backToPlaylists() {
    setOpenedPlaylistId('')
    setOpenedPlaylistName('')
    setPlaylistIsOpen(false)
  }

  const filteredPlaylists = useMemo(() => {
    const lowerSearch = search.toLowerCase()

    return playlists.filter((playlist) => playlist.nome.toLowerCase().includes(lowerSearch))
  }, [search, playlists])

  return (
    <>
      <PlayerBar />
      <NavBar />
      <div className={menuState ? 'pageContainerMenuOpen playlistsPage' : 'pageContainerMenuClosed playlistsPage'}>
        <div className='pageHeader'>
          <h1 style={{ marginLeft: '15px' }}>{openedPlaylistName !== '' ? openedPlaylistName : 'Playlists'}</h1>
          {playlistIsOpen ? <RiArrowGoBackFill onClick={backToPlaylists} className='backButton' /> : ''}
        </div>

        {playlistIsOpen ? '' : (
          <Box
            component='form'
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete='off'
          >
            <TextField style={{ marginLeft: '15px' }} className='searchField' label={<FaSearch style={{ color: 'white' }} />} variant='standard' value={search} onChange={(e) => setSearch(e.target.value)} />
          </Box>
        )}

        {playlistIsOpen ? (<MusicsList playlistId={openedPlaylistId} />) : isDownloading ? (<div className='beatLoaderIcon'> <BeatLoader color={loaderColor} size={150} />  Fazendo download das músicas... </div>) : !isLoading ? (
          <>
            <div className='playlistsContainer'>
              {
                filteredPlaylists.map(playlist => (
                  <figure key={playlist.id}>
                    <img className='playlistsImage' src={playlist.thumbnail ? playlist.thumbnail : BigLogo} alt='playlist' />
                    <figcaption style={{ fontWeight: 'bolder' }}>{playlist.nome}</figcaption>
                    <div onClick={() => enterPlaylist(playlist.id, playlist.nome)} className='enterIconWraper'><ImEnter className='enterIcon' /></div>
                  </figure>
                ))
              }
            </div>
          </>
        ) : (<div className='loaderIcon'> <ClipLoader color={loaderColor} size={150} /> </div>)
        }
      </div>
    </>
  )
}
