import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import VanillaTilt from 'vanilla-tilt';

import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar'

import { FaList, FaMusic, FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa'
import { AiFillHeart } from 'react-icons/ai'
import { FiHome, FiLogOut } from 'react-icons/fi'
import { BsPlusLg } from 'react-icons/bs'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import 'react-pro-sidebar/dist/css/styles.css'
import './style.css'
import BigLogo from '../../../src/assets/256x256.png'

function NavBar() {
    const history = useNavigate()
    const dispatch = useDispatch()
    let location = useLocation()

    const menuState = useSelector((state) => state.menuIsCollapsed)
    const [ menuCollapse, setMenuCollapse ] = useState(!menuState)
    const [ playlistsActive, setPlaylistsActive ] = useState(false)
    const [ musicsActive, setMusicsActive ] = useState(false)
    const [ favsActive, setFavsActive ] = useState(false)
    const [ newJingleActive, setNewJingleActive ] = useState(false)
    const [ homeActive, setHomeActive ] = useState(false)
    const [ createJingleOption, setCreateJingleOption ] = useState(false)
    const [ open, setOpen ] = useState(false)
    const musicIsPlaying = useSelector((state) => state.musicIsPlaying)

    const handleModalOpen = () => setOpen(true)
    const handleModalClose = () => setOpen(false)

    useEffect(() => {
      let cancel = false

      if (cancel) return

      localStorage.getItem("config", async (err, data) => {
        let dataJson = JSON.parse(data)
  
        if (dataJson.ambiente_create_jingle) {
          setCreateJingleOption(true)
        } else if (dataJson.maquina_create_jingle) {
          setCreateJingleOption(true)
        }
      })

      dispatch({ type: 'MENU_STATE', menuState: !menuCollapse })

      if (location.pathname === '/home') {
        setHomeActive(true)
        setPlaylistsActive(false)
        setMusicsActive(false)
        setNewJingleActive(false)
        setFavsActive(false)

      } else if (location.pathname === '/musics') {
        setHomeActive(false)
        setPlaylistsActive(false)
        setNewJingleActive(false)
        setMusicsActive(true)
        setFavsActive(false)
      }
      else if (location.pathname === '/favs') {
        setHomeActive(false)
        setPlaylistsActive(false)
        setNewJingleActive(false)
        setMusicsActive(false)
        setFavsActive(true)
      } else if (location.pathname === '/playlists') {
        setHomeActive(false)
        setPlaylistsActive(true)
        setMusicsActive(false)
        setNewJingleActive(false)
        setFavsActive(false)

      } else if (location.pathname === '/configs') {
        setHomeActive(false)
        setPlaylistsActive(false)
        setMusicsActive(false)
        setNewJingleActive(false)
        setFavsActive(false)

      } else if (location.pathname === '/newJingle') {
        setHomeActive(false)
        setPlaylistsActive(false)
        setMusicsActive(false)
        setNewJingleActive(true)
        setFavsActive(false)

      }

      return () => { 
        cancel = true
      }
      // eslint-disable-next-line
    }, [menuCollapse, location.pathname])

    const modalStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
    }

    const buttonStyle = {
      marginRight: '15px',
      backgroundColor: '#74ac2d',
      color: 'black',
      fontWeight: 'bold'
    }

    const styleClosedMenu = {
      right: '1.6rem'
    }

    const styleOpenedMenu = {
      right: '1rem'
    }

    const circleStyle = {
      color: '#c5c4c0'
    }

    const menuIconClick = () => {
      menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true)

      dispatch({ type: 'MENU_STATE', menuState: menuCollapse })
    }

    const closeApp = (e) => {
      e.preventDefault()

      localStorage.clear()

      if(musicIsPlaying != null) {
        musicIsPlaying.pause()
        dispatch({ type: 'SET_IF_MUSIC_IS_PAUSED', musicIsPaused:  true })
      }
        
      try {
          localStorage.removeItem("config")
          history('/')
      } catch (err) {
        console.log(err)
          const hashObj = { data: new Date().toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'}), codErro: err.response.status, mensagemErro: err.message, URL: err.request.responseURL }
          localStorage.setItem("falhas", JSON.stringify(hashObj), (err) => {
            if (err) {
              throw err
            }
          })
      }
    }
  /* \/ function Vanilla.tilt \/ */
  function Tilt(props) {
    const { options, ...rest } = props;
    const tilt = useRef(null);

    useEffect(() => {
      VanillaTilt.init(tilt.current, options);
    }, [options]);

    return <div ref={tilt} {...rest} />;
  }
  const options = {
    scale: 1.0,
    speed: 500,
    max: 11,
    glare: true,
    "max-glare": 0.4,
  };
  const optionsLogo = {
    scale: 1.0,
    speed: 100,
    max: 15,
    reverse: true,
  };
  /* /\ function Vanilla.tilt /\ */
    return (
      <>
        <div id='header'>
          <ProSidebar collapsed={menuCollapse}>
            <SidebarHeader>
              <Tilt options={optionsLogo} className='logoContainer'>
                <img id='bigLogo' src={BigLogo} alt='BigLogo' />
              </Tilt>

              <div className='closemenu' style={menuCollapse ? styleClosedMenu : styleOpenedMenu} onClick={menuIconClick}>
                {menuCollapse ? (
                  <FaArrowCircleRight id='FaArrowCircleRight' style={circleStyle}/>
                ) : (
                  <FaArrowCircleLeft id='FaArrowCircleLeft' style={circleStyle}/>
                )}
              </div>
            </SidebarHeader>
            <div className='overflow-scroll'>
              <SidebarContent id='pro-sidebar-content'>

                <Menu iconShape='square'>
                  <div className='overflow-scroll'>
                    <Tilt options={options}>
                      <MenuItem className='pro-sidebar-menuItem' onClick={() => history('/home')} active={homeActive} icon={<FiHome className='pro-sidebar-icon' />}>Home</MenuItem>
                    </Tilt>
                    <Tilt options={options}>
                      <MenuItem className='pro-sidebar-menuItem' onClick={() => history('/playlists')} active={playlistsActive} icon={<FaList className='pro-sidebar-icon' />}>Playlists</MenuItem>
                    </Tilt>
                    <Tilt options={options}>
                      <MenuItem className='pro-sidebar-menuItem' onClick={() => history('/favs')} active={favsActive} icon={<AiFillHeart className='pro-sidebar-icon' />}>Favoritas</MenuItem>
                    </Tilt>
                    <Tilt options={options}>
                      <MenuItem className='pro-sidebar-menuItem' onClick={() => history('/musics')} active={musicsActive} icon={<FaMusic className='pro-sidebar-icon' />}>Músicas</MenuItem>
                    </Tilt>                  
                    <Tilt options={options}>
                  { createJingleOption ? (
                        <MenuItem className='pro-sidebar-menuItem' onClick={() => history('/newJingle')} active={newJingleActive} icon={<BsPlusLg className='pro-sidebar-icon' />}>Criar Jingle</MenuItem>
                  ) : '' }                  
                    </Tilt>
                  </div>
                </Menu>
              </SidebarContent>
            </div>
            <SidebarFooter>
              <Menu id='pro-sidebar-menuSair' iconShape='square'>
                <Tilt options={options}>
                  <MenuItem id='pro-sidebar-menuLogout' onClick={handleModalOpen} icon={<FiLogOut className='pro-sidebar-icon' />}>Sair</MenuItem>
                </Tilt>
              </Menu>
            </SidebarFooter>
          </ProSidebar>

          <Modal
            open={open}
            onClose={handleModalClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={modalStyle}>
              <Typography id='modal-modal-title' variant='h6' component='h2' style={{ 'marginBottom': '15px' }}>
                Tem certeza que deseja fechar?
              </Typography>
              <div className='buttonsContainer'>
                <Button style={buttonStyle} className='yesButton' variant='contained' onClick={ closeApp }>Sim</Button>
                <Button style={buttonStyle} variant='contained' onClick={handleModalClose}>Não</Button>
              </div>
            </Box>
          </Modal>
        </div>
      </>
    )
}

export default NavBar