import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default function Audio() {
  const dispatch = useDispatch()
  const [periodicidadeCounter, setPeriodicidadeCounter] = useState(0)
  const musicIsPlaying = useSelector((state) => state.musicIsPlaying)
  const musics = useSelector((state) => state.musicsArray)
  const jingles = useSelector((state) => state.jinglesArray)
  const currentMusicName = useSelector((state) => state.currentMusicName)
  const currentJingleName = useSelector((state) => state.currentJingleName)
  const periodicidadeJingle = useSelector((state) => state.periodicidadeJingle)
  const randomIsActive = useSelector((state) => state.randomIsActive)

  const getRandom = (max) => {
    return Math.floor(Math.random() * max)
  }

  function nextMusic() {
    const falhasObj = JSON.parse(localStorage.getItem('config') || '{}').falhas
  
    if (randomIsActive) {
      if (currentMusicName) {
        const randomMusicIndex = getRandom(musics.length - 1)
  
        musicIsPlaying.src = musics[randomMusicIndex].streaming_url
  
        dispatch({ type: 'SET_IF_MUSIC_IS_PAUSED', musicIsPaused: false })
  
        const playPromise = musicIsPlaying.play()
  
        dispatch({ type: 'ALLOW_PLAY_JINGLE', allowPlayJingle: false })
  
        if (playPromise !== null) {
          playPromise.catch((err) => {
            if (!falhasObj) {
              const hashObj = {
                data: new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }),
                codErro: err.response,
                mensagemErro: err.message,
                URL: err.request,
              }
              localStorage.setItem('falhas', JSON.stringify(hashObj))
            } else {
              const hashObj = {
                data: new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }),
                codErro: err.response,
                mensagemErro: err.message,
                URL: err.request,
              }
              localStorage.setItem('falhas', falhasObj + '\r\n' + JSON.stringify(hashObj))
            }
            nextMusic()
          })
        }
  
        dispatch({ type: 'CURRENT_MUSIC_NAME', musicName: musics[randomMusicIndex].arquivo })
  
        dispatch({
          type: 'CURRENT_MUSIC_DATA',
          musicData: {
            name: musics[randomMusicIndex].nome,
            artist: musics[randomMusicIndex].artista,
            thumbnail: musics[randomMusicIndex].playlist_thumbnail,
          },
        })
      }
    } else {
      const currentSong = musics.filter((music) => music.arquivo === currentMusicName)
  
      if (musics.indexOf(currentSong[0]) + 1 !== musics.length) {
        const nextSong = musics[musics.indexOf(currentSong[0]) + 1]
  
        musicIsPlaying.src = nextSong.streaming_url
  
        const playPromise = musicIsPlaying.play()
  
        dispatch({ type: 'ALLOW_PLAY_JINGLE', allowPlayJingle: false })
  
        if (playPromise !== null) {
          playPromise.catch((err) => {
            if (!falhasObj) {
              const hashObj = {
                data: new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }),
                codErro: err.response,
                mensagemErro: err.message,
                URL: err.request,
              }
              localStorage.setItem('falhas', JSON.stringify(hashObj))
            } else {
              const hashObj = {
                data: new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }),
                codErro: err.response,
                mensagemErro: err.message,
                URL: err.request,
              }
              localStorage.setItem('falhas', falhasObj + '\r\n' + JSON.stringify(hashObj))
            }
            nextMusic()
          })
        }
  
        dispatch({ type: 'CURRENT_MUSIC_NAME', musicName: nextSong.arquivo })
  
        dispatch({
          type: 'CURRENT_MUSIC_DATA',
          musicData: {
            name: nextSong.nome,
            artist: nextSong.artista,
            thumbnail: nextSong.playlist_thumbnail,
          },
        })
      } else {
        musicIsPlaying.src = musics[0].streaming_url
  
        const playPromise = musicIsPlaying.play()
  
        dispatch({ type: 'ALLOW_PLAY_JINGLE', allowPlayJingle: false })
  
        if (playPromise !== null) {
          playPromise.catch((err) => {
            if (!falhasObj) {
              const hashObj = {
                data: new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }),
                codErro: err.response,
                mensagemErro: err.message,
                URL: err.request,
              }
              localStorage.setItem('falhas', JSON.stringify(hashObj))
            } else {
              const hashObj = {
                data: new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }),
                codErro: err.response,
                mensagemErro: err.message,
                URL: err.request,
              }
              localStorage.setItem('falhas', falhasObj + '\r\n' + JSON.stringify(hashObj))
            }
            nextMusic()
          })
        }
  
        dispatch({ type: 'CURRENT_MUSIC_NAME', musicName: musics[0].arquivo })
  
        dispatch({
          type: 'CURRENT_MUSIC_DATA',
          musicData: {
            name: musics[0].nome,
            artist: musics[0].artista,
            thumbnail: musics[0].playlist_thumbnail,
          },
        })
      }
    }
  }
  

  function nextJingle() {
    const falhasObj = JSON.parse(localStorage.getItem('config') || '{}').falhas
    const currentJingle = jingles.filter(jingle => jingle.arquivo === currentJingleName)
  
    if (jingles.indexOf(currentJingle[0]) + 1 !== jingles.length) {
      const nextJingle = jingles[jingles.indexOf(currentJingle[0]) + 1]
  
      musicIsPlaying.src = nextJingle.streaming_url
  
      const playPromise = musicIsPlaying.play()
  
      dispatch({ type: 'ALLOW_PLAY_JINGLE', allowPlayJingle: true })
  
      if (playPromise !== null) {
        playPromise.catch((err) => {
          if (!falhasObj) {
            const hashObj = {
              data: new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }),
              codErro: err.response,
              mensagemErro: err.message,
              URL: err.request,
            }
            localStorage.setItem('falhas', JSON.stringify(hashObj))
          } else {
            const hashObj = {
              data: new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }),
              codErro: err.response,
              mensagemErro: err.message,
              URL: err.request,
            }
            localStorage.setItem('falhas', falhasObj + "\r\n" + JSON.stringify(hashObj))
          }
          nextMusic()
        })
      }
  
      dispatch({ type: 'CURRENT_JINGLE_NAME', jingleName: nextJingle.arquivo })
  
      dispatch({
        type: 'CURRENT_JINGLE_DATA',
        jingleData: {
          name: nextJingle.nome,
          artist: nextJingle.artista,
          thumbnail: nextJingle.playlist_thumbnail,
        },
      })
    } else {
      musicIsPlaying.src = jingles[0].streaming_url
  
      const playPromise = musicIsPlaying.play()
  
      dispatch({ type: 'ALLOW_PLAY_JINGLE', allowPlayJingle: true })
  
      if (playPromise !== null) {
        playPromise.catch((err) => {
          if (!falhasObj) {
            const hashObj = {
              data: new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }),
              codErro: err.response,
              mensagemErro: err.message,
              URL: err.request,
            }
            localStorage.setItem('falhas', JSON.stringify(hashObj))
          } else {
            const hashObj = {
              data: new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }),
              codErro: err.response,
              mensagemErro: err.message,
              URL: err.request,
            }
            localStorage.setItem('falhas', falhasObj + "\r\n" + JSON.stringify(hashObj))
          }
          nextMusic()
        })
      }
  
      dispatch({ type: 'CURRENT_JINGLE_NAME', jingleName: jingles[0].arquivo })
  
      dispatch({
        type: 'CURRENT_JINGLE_DATA',
        jingleData: {
          name: jingles[0].nome,
          artist: jingles[0].artista,
          thumbnail: jingles[0].playlist_thumbnail,
        },
      })
    }
  }  

  function changeJinglesCounter() {
    if (periodicidadeCounter >= periodicidadeJingle) {
      setPeriodicidadeCounter(0)
    } else {
      setPeriodicidadeCounter(periodicidadeCounter + 1)
    }
  }

  function selectNextFileToPlay() {
    musicIsPlaying.pause()
    if (periodicidadeJingle !== 0 && jingles.length > 0 && periodicidadeCounter >= periodicidadeJingle) {
      nextJingle()
    } else {
      nextMusic()
    }
  }

  useEffect(() => {
    let cancel = false

    if (cancel) return

    dispatch({ type: 'MUSIC_IS_PLAYING', musicPlayer: document.getElementById('audioPlayer') })

    return () => {
      cancel = true
    }
  }, [dispatch, currentMusicName, currentJingleName])

  return (
    <audio onLoadStart={changeJinglesCounter} onEnded={selectNextFileToPlay} id="audioPlayer" display="none">
      <source src="" type="audio/mp3" />
    </audio>
  )
}
