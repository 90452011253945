import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import NavBar from '../../components/navbar'
import PlayerBar from '../../components/playerbar'
import { useSelector, useDispatch } from 'react-redux'
import api from '../../services/api'
import './style.css'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { FaPlay, FaPause, FaSearch } from 'react-icons/fa'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import ClipLoader from 'react-spinners/ClipLoader'
import Pagination from '@mui/material/Pagination'
import { createTheme, ThemeProvider } from '@mui/material/styles'

export default function Music() {
    const [page, setPage] = useState(1)

    const handleChange = (event, value) => {
      setPage(value)
    }

    const theme = createTheme({
      palette: {
        buttonActive: {
          main: '#74ac2d',
        }
      }
    })

    const history = useNavigate()
    const dispatch = useDispatch()
    const menuState = useSelector((state) => state.menuIsCollapsed)
    const musicIsPlaying = useSelector((state) => state.musicIsPlaying)
    const musicIsPaused = useSelector((state) => state.musicIsPaused)
    const currentMusicName = useSelector((state) => state.currentMusicName)
    const allowPlayJingle = useSelector((state) => state.allowPlayJingle)
    const [musics, setMusics] = useState([])
    const [search, setSearch] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [musicsPerPage] = useState(10)

    const rowsPerPage = 10
    const startIndex = (page - 1) * rowsPerPage
    const endIndex = startIndex + rowsPerPage
    // eslint-disable-next-line
    let [loaderColor, setLoaderColor] = useState("#ffffff")

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }))
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }))

    const tableCellStyle = {
      color: '#B4B4B4'
    }

    function playMusic(musicName, playlistName, streamingUrl) {
      if (allowPlayJingle) {
        dispatch({ type: 'ALLOW_PLAY_JINGLE', allowPlayJingle:  false }) 
      }

      var playlistDetails = JSON.parse(localStorage.getItem('jinglesData') || '{}')[playlistName]
      if (playlistDetails) {
        dispatch({ type: 'PERIODICIDADE_JINGLE', periodicidadeJingle: playlistDetails[0].periodicidade_jingle })
      }

      if (musicIsPaused && musicName === currentMusicName) {
        musicIsPlaying.play()

        dispatch({ type: 'SET_IF_MUSIC_IS_PAUSED', musicIsPaused:  false })
      } else {
        dispatch({ type: 'SET_IF_MUSIC_IS_PAUSED', musicIsPaused:  false })

        const currentMusicData = musics.filter(music => music.arquivo === musicName)

        dispatch({ type: 'MUSICS_ARRAY', musicsArray:  musics })

        musicIsPlaying.src = streamingUrl

        dispatch({ type: 'MUSIC_IS_PLAYING', musicPlayer:  musicIsPlaying })

        dispatch({ type: 'CURRENT_MUSIC_NAME', musicName:  musicName })

        dispatch({ type: 'CURRENT_MUSIC_DATA', musicData: {
          name: currentMusicData[0].nome,
          artist: currentMusicData[0].artista,
          thumbnail: currentMusicData[0].playlist_thumbnail
        }})

        const playPromise = musicIsPlaying.play()

        if (playPromise !== null) {
          playPromise.catch((err) => {
            // Handle error here
          })
        }

        musicIsPlaying.volume = 50 / 100
      }
    }

    function pauseMusic() {
      musicIsPlaying.pause()
      dispatch({ type: 'SET_IF_MUSIC_IS_PAUSED', musicIsPaused:  true })
    }

    useEffect(() => {
        let cancel = false

        async function fetchData(hash) {
          await api.get(`/music/infoMusicsByHash/${hash}`).then((res) => {
            if (cancel) return

            setIsLoading(false)
            setMusics(res.data)
          })  
        }

        if (localStorage.getItem("config") != null) {
          var dataJson = JSON.parse(localStorage.getItem("config"))
          
          fetchData(dataJson.hash)

          if (cancel) return
          
        } else {
          history('/')
        }
        
        return () => { 
          cancel = true
        }
    }, [history])  

    const filteredMusics = useMemo(() => {
      const lowerSearch = search.toLowerCase()

      if (search === '') {
        return musics
      } else {
        return musics.filter((music) => music.nome?.toLowerCase().includes(lowerSearch) || music.artista?.toLowerCase().includes(lowerSearch) || music.album?.toLowerCase().includes(lowerSearch))
      }
    }, [search, musics])

    const nPages = Math.ceil(filteredMusics.length / musicsPerPage)

    const slicedData = filteredMusics.slice(startIndex, endIndex)

    return (
        <>
          <NavBar/>
          <PlayerBar/>  
          <div className={menuState ? 'pageContainerMenuOpen musicsPageAll' : 'pageContainerMenuClosed musicsPageAll'}>
            <h1 style={{ marginLeft: '15px' }}>Músicas</h1>

            <Box
              component='form'
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete='off'
            >
              <TextField style={{ marginLeft: '15px' }} className='searchField' label={ <FaSearch style={{ color: 'white' }} /> } variant='standard' value={search} onChange={(e) => setSearch(e.target.value)} />
            </Box>
            
            { !isLoading ? (
              <TableContainer component={Paper}>
                <Table aria-label='customized table'>
                  <TableHead>
                    <TableRow>
                    <StyledTableCell style={tableCellStyle} align='center'>Reproduzir</StyledTableCell>
                      <StyledTableCell style={tableCellStyle}>Nome</StyledTableCell>
                      <StyledTableCell style={tableCellStyle} align='center'>Artista</StyledTableCell>
                      <StyledTableCell style={tableCellStyle} align='center'>Álbum</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className='tableBody'>
                    {slicedData.map((music) => (
                      <StyledTableRow key={`${music.id_musica}_${music.pivot.id_playlist}`}>
                        <StyledTableCell style={tableCellStyle} align='center'>
                            { music.arquivo === currentMusicName && !musicIsPaused && !allowPlayJingle ? (
                                  <IconButton onClick={pauseMusic} aria-label='play'>
                                    <FaPause className='playButton' />
                                  </IconButton>
                                ) : (
                                  <IconButton onClick={() => playMusic(music.arquivo, music.playlist_name, music.streaming_url)} aria-label='play'>
                                    <FaPlay className='playButton' />
                                  </IconButton>
                                ) 
                            }
                        </StyledTableCell>
                        <StyledTableCell style={{ color: 'white', fontWeight: 'bold' }} component='th' scope='row'>
                          {music.nome}
                        </StyledTableCell>
                        <StyledTableCell style={tableCellStyle} align='center'>{music.artista}</StyledTableCell>
                        <StyledTableCell style={tableCellStyle} align='center'>{music.album}</StyledTableCell>  
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : ( <div className='loaderIcon'> <ClipLoader color={loaderColor} size={150} /> </div> ) }

            <ThemeProvider theme={theme}>
              <Pagination size='large' style={{ backgroundColor: '#58585a', height: '48px', paddingTop: '4px' }} color="buttonActive" count={nPages} page={page} onChange={handleChange} />
            </ThemeProvider>

          </div>
        </>
    )
}